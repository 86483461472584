<template>
	<div class="">
		<a-spin :spinning="loading">
			<div class="flex space alcenter">
				<div class="ft20 cl-main ftw600">{{getTitle}}</div>
				<a-button @click="$router.go(-1)">返回</a-button>
			</div>
			
			<div class="mt20 bg-w pd40">
				<div style="width: 1000px;">
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
							
							<a-form-model-item label="拼团商品" required>
								<div v-if="JSON.stringify(form.goods) == '{}'" @click="chooseGoodsAct()" class="edit-flash-sale-goods-it ml10 clickAct flex center alcenter cl-theme ft16 ftw600">
									选择商品<i class="iconfont iconicon_arrowr ft16 ml5"/>
								</div>
								
								<div v-else class="edit-flash-sale-goods-not-it flex space">
									<div style="width: 339px;border-right: 1px solid #DCDFE6;" class="flex">
										<img v-if="form.goods.cover_img != null" :src="form.goods.cover_img"/>
										<div class="ml10">
											<div class="ft14 ftw400 cl-black text-over4">{{form.goods.name}}</div>
											<div class="ft12 ftw400 cl-notice mt15 ">门市原价：¥{{form.goods.original_price}}</div>
										</div>
									</div>
									<div class="flex alcenter cente ft16 ftw600 cl-theme clickAct" @click="chooseGoodsAct()">
										重新选择<i class="iconfont iconicon_arrowr ft16 ml5"/>
									</div>
								</div>
							</a-form-model-item>
							
							<a-form-model-item label="成团人数" required>
								<a-input-number v-model="form.group_num"  :min="0" :precision="0" placeholder="请输入" style="width: 160px;" />
							</a-form-model-item>
							
							<a-form-model-item label="成团价格" required>
								<a-input-number v-model="form.pin_tuan_price" :formatter="value => `${value} 元`" :min="0" :precision="2" placeholder="请输入" style="width: 160px;" />
							</a-form-model-item>
							
							<a-form-model-item label="拼团时间" required>
								<a-range-picker @change="changePinTuanTime" v-model="form.pin_tuan_time"  format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" />
							</a-form-model-item>
							
							<a-form-model-item label="允许购买的数量" required >
								<a-input-number :formatter="value => `${value} 个`" v-model="form.quota" :min="0" :precision="0"
								 placeholder="请输入" />
							</a-form-model-item>
							
							<a-form-model-item label="展示已售的数量" required >
								<a-input-number :formatter="value => `${value} 个`" v-model="form.fictitious_take_count" :min="0" :precision="0"
								 placeholder="请输入" />
							</a-form-model-item>
							
							<a-form-model-item required label="是否首推">
								<a-radio-group v-model="form.is_tui">
									<a-radio :value="1">是</a-radio>
									<a-radio :value="0">否</a-radio>
								</a-radio-group>
							</a-form-model-item>
							
							<a-form-model-item required label="商品规格" v-if="form.is_have_sku==1">
								<div class="flex alcenter">
									<!-- <a-radio-group v-model="form.is_have_sku">
										<a-radio :value="1">有</a-radio>
										<a-radio :value="0">无</a-radio>
									</a-radio-group> -->
									 <div class="ml10 cl-red"><!-- 开启规格后 -->库存就是规格里库存的总和</div>
								</div>
								
								<div class="mt10" v-if="form.is_have_sku==1">
									<table class="wxb-table list" style="width: 100%;">
										<thead>
											<th>规格名称</th>
											<th>门店原价</th>
											<th>拼团价格</th>
											<th>库存</th>
											<th>操作</th>
										</thead>
										<tbody>
											<tr v-for="(item,index) in form.sku">
												<td>
													<a-input  placeholder="请输入" v-model="item.name"></a-input>
												</td>
												<td>
													<a-input-number v-model="item.original_price" :formatter="value => `${value}元`" placeholder="请输入" :precision="2" :min="0" />
												</td>
												<td>
													<a-input-number  v-model="item.pin_tuan_price" :formatter="value => `${value}元`" placeholder="请输入" :precision="2" :min="0" />
												</td>
												<td>
													<a-input-number v-model="item.quota"  placeholder="请输入" :precision="0" :min="0" />
												</td>
												<td>
													<div class="clickAct" @click="delSkuItem(index)">
														<i class="iconfont iconbtn_close ft20 cl-notice "/>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
									
								</div>
							</a-form-model-item>
							
						
							
							<a-form-model-item label="拼团须知" required >
								<div class="ft12 ftw400 cl-notice">不填就显示默认内容，可修改</div>
								
								<a-textarea  v-model="form.pin_tuan_info" :rows="11" :maxLength="250"/>
							</a-form-model-item>
						
						
							<a-form-model-item :wrapper-col="{ span: 2, offset: 1 }" class="mt20">
								<a-button type="primary" @click="onSubmit">
									保存上架
								</a-button>
						
							</a-form-model-item>
						</a-form-model>
					
				</div>
			
				<div v-if="chooseGoodsVisible">
					<choose-goods v-model="form.goods" :visible="chooseGoodsVisible" @cancel="cancelChooseGoods" @ok="okChooseGoods"></choose-goods>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import chooseGoods from './components/EditPinTuanGoods/modal/choosePinTuanGoods.vue';;
	export default{
		components:{
			chooseGoods,
		},
		data(){
			return {
				loading:false,
				confirmLoading:false,
				chooseGoodsVisible:false,
				labelCol: { span: 4 },
			    wrapperCol: { span: 20 },
				pin_tuan_goods_id:0,
				form: {
					goods:{},
					group_num:'',
					pin_tuan_price:'',
					pin_tuan_time:[],
					quota:'',
					fictitious_take_count:'',
					is_tui:0,
					is_have_sku:0,
					sku:[],
					pin_tuan_info:'',
				}
			}
		},
		created() {
			if (!this.$route.query.id) {
				this.pin_tuan_goods_id=0;
			} else {
				this.pin_tuan_goods_id = parseInt(this.$route.query.id);
			}
			this.loaddata();
		},
		computed:{
			getTitle(){
				if(this.pin_tuan_goods_id==0){
					return '添加拼团商品';
				}else{
					return '编辑拼团商品';
				}
			}
		},
		methods:{
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showPinTuanGoods',{
					pin_tuan_goods_id:this.pin_tuan_goods_id
				}).then(res=>{
					this.loading=false;
					if(res.detail){
						this.form=res.detail;
					}
				}).catch(res=>{
					console.log(res);
					this.loading=false;
					this.$router.go(-1);
				})
			},
			
			onSubmit() {
				if(JSON.stringify(this.form.goods) == '{}'){
					this.$message.error('请选择商品');
					return;
				}
				
				if(!this.form.goods.goods_id){
					this.$message.error('请选择商品');
					return;
				}
				
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/savePinTuanGoods',{
					pin_tuan_goods_id:this.pin_tuan_goods_id,
					goods_id:this.form.goods.goods_id,
					group_num:this.form.group_num,
					pin_tuan_price:this.form.pin_tuan_price,
					pin_tuan_time:this.form.pin_tuan_time,
					quota:this.form.quota,
					fictitious_take_count:this.form.fictitious_take_count,
					is_tui:this.form.is_tui,
					is_have_sku:this.form.is_have_sku,
					sku:JSON.stringify(this.form.sku),
					pin_tuan_info:this.form.pin_tuan_info,
				}).then(res=>{
					this.$message.success('保存成功');
					this.$router.go(-1);
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			delSkuItem(index){
				this.form.sku.splice(index,1);
			},
			
			changePinTuanTime(value){
				this.form.pin_tuan_time=value;
			},
			
			chooseGoodsAct(){
				this.chooseGoodsVisible=true;
			},
			cancelChooseGoods(){
				this.chooseGoodsVisible=false;
			},
			okChooseGoods(){
				this.chooseGoodsVisible=false;
				this.form.quota=this.form.goods.quota;
				this.form.is_tui=this.form.goods.is_tui;
				this.form.is_have_sku=this.form.goods.is_have_sku;
				
				if(this.form.is_have_sku==1){
					this.form.sku=[];
					let sku =JSON.parse(JSON.stringify(this.form.goods.sku));
					for(var a in sku){
						sku[a].pin_tuan_price='';
					}
					this.form.sku=sku
				}
			},
			
			
		}
	}
</script>

<style>
	.edit-flash-sale-goods-it{
		width: 400px;
		height: 100px;
		background: #FFFFFF;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
	}
	
	.edit-flash-sale-goods-not-it{
		width: 480px;
		height: 100px;
		background: #FFFFFF;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		padding: 20px;
		line-height: 20px;
	}
	
	.edit-flash-sale-goods-not-it img{
		width: 60px;
		height: 60px;
	}
</style>